import {Api} from "@/api";
import {ISelect} from "@/components/Select/helper";

const getCountryItem = async () => {
    let json: ISelect[];
    if (!sessionStorage.getItem('countryItem')) {
        const res = await Api.Country.GetZcountryList()
        json = res?.data?.reduce((result: ISelect[], i: { code: string, name: string }, index: number) => {
            result[index] = {
                label: i.name,
                value: i.code
            }
            return result
        }, [])
        if (json) {
            sessionStorage.setItem('countryItem', JSON.stringify(json))
        }
    } else {
        json = JSON.parse(sessionStorage.getItem('countryItem') || '')
    }
    return json
}

// const getCountryMap
export const commonInfo = () => {
    return {
        countryItem: () => getCountryItem()
    }
}